import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../resources/logo.png';
import { Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode';





function NavBar () {

    const [i, setI] = useState('');
    const [iUserProfie, setIUserProfile] = useState('');
    const navigate = useNavigate();
    const [decoded, setDecoded] = useState({});
    const token = localStorage.getItem('token');
    
    
    
  
    useEffect(() => {
      
      if(token){
        setI("Deconecteaza-te");
        setIUserProfile("Contul meu");}
      else{
        setI("Conecteaza-te") ;
        setIUserProfile("");}

        if(token){
          setDecoded(jwt_decode(token));}
      
        
  
  
      
      
    }, [token]);


    return (
        <div>
             <nav id="navig" >
            
            <Link to="/"  id="logo">
              <div classname="img-logo">
                <img src={logo} style={{height:50}} alt="logo" />
              </div>
              
            </Link>
          
             <div className='rightc'>
              {token && decoded.admin===1  ? (
              <Link to="/admin" >
                <button className="nav-btn">Admin</button></Link>) : null}
              {token ? (
                <Link to="/userprofile" >
                  <button className="nav-btn">Contul meu</button>
                </Link>
                
              ): null}
              {/* <Link to="/userprofile" >
                <div style={{color: "black"}}>{iUserProfie}</div></Link> */}
              <button className="nav-btn" onClick={() => {if (token) {
                 navigate('/adaugareanunt');
                } else {
                    alert("Trebuie sa fii autentificat sa postezi");
                    navigate('/signinup');
                }}}>Adaugare Anunt</button>
                
              <button className="nav-btn" onClick={() => {
                if(token){
                  localStorage.removeItem('token');
                  setI("Conecteaza-te");
                  
                  } else {
                    navigate('/signinup');
                    
                  }
                    }
                    }>{i}</button>
              
              </div> 


            
            
          
        </nav>

        </div>
    )



}

export default NavBar;