import React, { useState } from 'react';
import SearchPage from './searchbar.css';
import { useNavigate } from 'react-router-dom';

const SearchBar = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedCounty, setSelectedCounty] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const navigate = useNavigate();
  
  const counties = ["Alba", "Arad", "Argeș", "Bacău", "Bihor", "Bistrița-Năsăud", "Botoșani", "Brașov", "Brăila", "Buzău", "Caraș-Severin", "Călărași", "Cluj", "Constanța", "Covasna", "Dâmbovița", "Dolj", "Galați", "Giurgiu", "Gorj", "Harghita", "Hunedoara", "Ialomița", "Iași", "Ilfov", "Maramureș", "Mehedinți", "Mureș", "Neamț", "Olt", "Prahova", "Satu Mare", "Sălaj", "Sibiu", "Suceava", "Teleorman", "Timiș", "Tulcea", "Vaslui", "Vâlcea", "Vrancea"]; // Replace with actual county names
  const jobCategories = [
    { label: 'Toate categoriile', value: '0', disabled: false },
    { label: 'Servicii', value: '', disabled: true },
    'Auto & Moto',
    'Transport',
    'Tractari',
    'Curatenie',
    'Constructii',
    'Infrumusetare',
    'IT & Electronice',
    'Consultanta',
    'Medicale',
    'Inchirieri auto',
    'Informatii',
    'Cazare/Turism',
    { label: 'Bunuri', value: '', disabled: true },
    'Auto',
    'Imobiliare',
    'IT&Electronice oferte',
    'Materiale constructii',
    { label: 'Job', value: '', disabled: true },
    'Bona',
    'Menajera',
    'Barman/ospatar',
    'Muncitor necalificat',
    { label: 'Website', value: '', disabled: true },
    'Creare website',
    'Design',
    'SEO',
    'Consulatanta',
    'Mentenanta'
  ]; // Replace with actual job categories

  const handleSearch = () => {
    const searchQuery = {
      searchText,
      selectedCounty,
      selectedCategory,
    };
    const queryString = `?searchText=${searchText}&selectedCounty=${selectedCounty}&selectedCategory=${selectedCategory}`;
    navigate(`/searchresults${queryString}`);
    ;
  };

  return (
    <div className='searchBar'>
      <input id="searchCauta"
        
        type="text"
        placeholder="Cauta servicii..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <select id="searchJudet"
        value={selectedCounty}
        onChange={(e) => setSelectedCounty(e.target.value)}
      >
        <option value="0">Toate judetele</option>
        {counties.map((county) => (
          <option key={county} value={county}>{county}</option>
        ))}
      </select>
      <select id="searchCategorie"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="0">Toate Categoriile</option>
        {jobCategories.map((category, index) => (
          <option
            key={index}
            value={typeof category === 'string' ? category : ''}
            disabled={typeof category !== 'string' && category.disabled}
            style={typeof category !== 'string' ? { fontWeight: 'bold', color: 'black' } : {}}
          >
            {typeof category === 'string' ? category : category.label}
          </option>
        ))}
      </select>
      <button onClick={handleSearch}>Cauta</button>
    </div>
  );
};

export default SearchBar;