import React from "react";
import Search from "./Search";
import SearchBar from "./SearchBar";
import Categories from "./Categories";
import {useState} from "react";
import "./layout.css";
import Footer from "./Footer";
import SearchU from "./SearchU";
import {Urgente} from "./Urgente";
import {signal} from "@preact/signals-react";
export const sresults = signal();



function Home () {

    const [searchData, setSearchData] = useState("");

    const searchTerm = (term) => {
        setSearchData(term);
    }
    console.log("home",sresults.value)

    return (
      <div className="container-home">
        <div className="home" >
            <div className="search-bar">
              <SearchBar/>
            </div>

            <div className="categories">
              <Categories  searchTerm ={searchTerm}/>
            </div>
            <div className="urgent">
              
              <SearchU searchTerm="urgente"/>
            </div>

            <div className="search">
              <Search searchTerm={searchData}/>
            </div>

            

        </div>
        <div className="footer">
          <Footer />
          </div>
        </div>
        

    )
} 

export default Home;