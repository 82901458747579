// import React from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import {useState} from "react";
// import "./Categories.css";


// function Categories ({searchTerm}) {

//     const [searchData, setSearchData] = useState("");

//     let servicii_principale = [
//         "Servicii de sănătate",
//         "Servicii educaționale",
//         "Servicii financiare",
//         "Servicii de consultanță în afaceri",
//         "Servicii IT și tehnologie",
//         "Servicii de marketing și publicitate",
//         "Servicii de catering și evenimente",
//         "Servicii de transport și logistică",
//         "Servicii de turism și ospitalitate",
//         "Servicii de construcții și reparații"
//     ]
    
    
    
//     const handleCategory = (term) => {
//         setSearchData(term);
//         searchTerm(term);
//     }
    
    
    
    
    
    
    

//     const responsive = {
//         superLargeDesktop: {
//           // the naming can be any, depends on you.
//           breakpoint: { max: 4000, min: 3000 },
//           items: 5
//         },
//         desktop: {
//           breakpoint: { max: 3000, min: 1024 },
//           items: 5
//         },
//         tablet: {
//           breakpoint: { max: 1024, min: 464 },
//           items: 2
//         },
//         mobile: {
//           breakpoint: { max: 464, min: 0 },
//           items: 1
//         }
//       };


//     return (
//         <div className="carouseld">
//             <Carousel responsive={responsive}>
//               {servicii_principale.map((category) => (
//         <div className="catbtn" key={category} onClick={() => handleCategory(category)}>
          
//             {category}</div>
        
//     ))}
               
//             </Carousel>

//         </div>
//     )
// }

// export default Categories;

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import "./Categories.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faGraduationCap, faDollarSign, faBriefcase, faLaptop, faBullhorn, faUtensils, faTruck, faPlane, faWrench } from "@fortawesome/free-solid-svg-icons";


// function Categories({ searchTerm }) {
//   const [searchData, setSearchData] = useState("");

//   let servicii_principale = [
//     "Servicii de sănătate",
//     "Servicii educaționale",
//     "Servicii financiare",
//     "Servicii de consultanță în afaceri",
//     "Servicii IT și tehnologie",
//     "Servicii de marketing și publicitate",
//     "Servicii de catering și evenimente",
//     "Servicii de transport și logistică",
//     "Servicii de turism și ospitalitate",
//     "Servicii de construcții și reparații"
//   ];

//   const handleCategory = (term) => {
//     setSearchData(term);
//     searchTerm(term);
//   };

//   const responsive = {
//     superLargeDesktop: {
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5,
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 5,
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 3,
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 3,
//     },
//   };

//   const categoryIcons = {
//     "Servicii de sănătate": faHospital,
//     "Servicii educaționale": faGraduationCap,
//     "Servicii financiare": faDollarSign,
//     "Servicii de consultanță în afaceri": faBriefcase,
//     "Servicii IT și tehnologie": faLaptop,
//     "Servicii de marketing și publicitate": faBullhorn,
//     "Servicii de catering și evenimente": faUtensils,
//     "Servicii de transport și logistică": faTruck,
//     "Servicii de turism și ospitalitate": faPlane,
//     "Servicii de construcții și reparații": faWrench,
//   };

//   return (
//     <div className="carouseld" id="carouseldiv">
//     <Carousel responsive={responsive} id="carousel">
//       {servicii_principale.map((category) => (
//         <div className="catbtn" key={category} onClick={() => handleCategory(category)}>
//           <FontAwesomeIcon icon={categoryIcons[category]} className="category-icon" />
//           {category}
//         </div>
//       ))}
//     </Carousel>
//   </div>
//   );
// }
const categories = {
  servicii: [
    'Auto & Moto',
    'Transport',
    'Tractari',
    'Curatenie',
    'Constructii',
    'Infrumusetare',
    'IT & Electronice',
    'Consultanta',
    'Medicale',
    'Inchirieri auto',
    'Informatii',
    'Cazare/Turism'
  ],
  bunuri: ['Auto', 'Imobiliare', 'IT&Electronice oferte', 'Materiale constructii'],
  job: ['Bona', 'Menajera', 'Barman/ospatar', 'Muncitor necalificat'],
  website: ['Creare website', 'Design', 'SEO', 'Consulatanta', 'Mentenanta'],
};

const categoryImages = {
  servicii: 'https://banner2.cleanpng.com/20180523/zxg/kisspng-computer-icons-customer-service-icon-design-it-ser-ub4we-engineering-services-5b04fd02de8b46.2830610615270535709116.jpg',
  bunuri: 'url_to_bunuri_image',
  job: 'url_to_job_image',
  website: 'url_to_website_image',
};


function Categories({ searchTerm }) {
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    searchTerm(category); // Pass the selected category to the parent component
  };

  return (
    

    <div className="categorii" style={{zIndex:"999"}}>
        <div className="dropdown-container">
          <div className="dropdown">
            <button className="dropbtn"><span class="material-symbols-outlined">
handyman
</span>Servicii</button>
            <div className="dropdown-content">
              {categories.servicii.map((category, index) => (
                <a className="category-drop" key={index} href="#" onClick={ ()=>handleCategoryChange(category) }>{category}</a>
              ))}
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn"><span class="material-symbols-outlined">
partner_exchange
</span>Bunuri</button>
            <div className="dropdown-content">
              {categories.bunuri.map((category, index) => (
                <a className="category-drop" key={index} href="#" onClick={ ()=>handleCategoryChange(category) }>{category}</a>
              ))}
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn">
            <span class="material-symbols-outlined">
work
</span>
              Job
            </button>
            <div className="dropdown-content">
              {categories.job.map((category, index) => (
                <a className="category-drop" key={index} href="#" onClick={ ()=>handleCategoryChange(category) }>{category}</a>
              ))}
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn"><span class="material-symbols-outlined">
web
</span>Website</button>
            <div className="dropdown-content">
              {categories.website.map((category, index) => (
                <a className="category-drop" key={index} href="#" onClick={ ()=>handleCategoryChange(category)}>{category}</a>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
}







export default Categories;

// <div className="categories-container">
    //   {Object.keys(categories).map((categoryType) => (
    //     <div key={categoryType} className="category-item">
    //       <img src={categoryImages[categoryType]} alt={categoryType} className="category-image" />
    //       <label htmlFor={categoryType}>{categoryType}</label>
    //       <select
    //         id={categoryType}
    //         value={selectedCategory}
    //         onChange={(e) => handleCategoryChange(e.target.value)}
    //       >
    //         <option value="">Select {categoryType}</option>
    //         {categories[categoryType].map((category) => (
    //           <option key={category} value={category}>
    //             {category}
    //           </option>
    //         ))}
    //       </select>
    //     </div>
    //   ))}
    // </div>