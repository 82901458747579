import React from "react";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
//import "./adaugareanunt.css";

import { useEffect } from "react";
import jwt_decode from 'jwt-decode';
import Footer from "./Footer";


const counties = ["Alba", "Arad", "Argeș", "Bacău", "Bihor", "Bistrița-Năsăud", "Botoșani", "Brașov", "Brăila", "Buzău", "Caraș-Severin", "Călărași", "Cluj", "Constanța", "Covasna", "Dâmbovița", "Dolj", "Galați", "Giurgiu", "Gorj", "Harghita", "Hunedoara", "Ialomița", "Iași", "Ilfov", "Maramureș", "Mehedinți", "Mureș", "Neamț", "Olt", "Prahova", "Satu Mare", "Sălaj", "Sibiu", "Suceava", "Teleorman", "Timiș", "Tulcea", "Vaslui", "Vâlcea", "Vrancea"];
  

const categories = [
  { label: 'Toate categoriile', value: '0', disabled: false },
  { label: 'Servicii', value: '', disabled: true },
  'Auto & Moto',
  'Transport',
  'Tractari',
  'Curatenie',
  'Constructii',
  'Infrumusetare',
  'IT & Electronice',
  'Consultanta',
  'Medicale',
  'Inchirieri auto',
  'Informatii',
  'Cazare/Turism',
  { label: 'Bunuri', value: '', disabled: true },
  'Auto',
  'Imobiliare',
  'IT&Electronice oferte',
  'Materiale constructii',
  { label: 'Job', value: '', disabled: true },
  'Bona',
  'Menajera',
  'Barman/ospatar',
  'Muncitor necalificat',
  { label: 'Website', value: '', disabled: true },
  'Creare website',
  'Design',
  'SEO',
  'Consulatanta',
  'Mentenanta'
];

function AdaugareAnunt() {

    const [numeAnunt, setNumeAnunt] = useState('');
    const [dataAdd, setDataAdd] = useState('');
    const [dataExp, setDataExp] = useState('');
    const [userID, setUserID] = useState('');
    const [userName, setUserName] = useState('');
    const [categorie, setCategorie] = useState('');
    const [locatie, setLocatie] = useState('');
    const [descriere, setDescriere] = useState('');
    const [phone, setPhone] = useState('');
    const [pret, setPret] = useState('');
    const [servicii, setServicii] = useState(0);
    const [urgent, setUrgent] = useState(0);
    

    const [selectedImage, setSelectedImage] = useState(null);
    // const handleImageUpload = (event) => {
    //     setSelectedImage(event.target.files[0]);
    //   };

    let navigate = useNavigate();
    const token = localStorage.getItem('token');
    console.log('token', jwt_decode(token));
    
    //setDatAdd with current date
    useEffect(() => {
        
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        setDataAdd(date);
        
    }, [token]);

    

    


    const handleFormSubmit = (e) => {
        e.preventDefault();
        
        const decoded = jwt_decode(token);
        
        let formData = new FormData();
        formData.append('numeAnunt', numeAnunt);
        formData.append('dataAdd', dataAdd);
        formData.append('dataExp', dataExp);
        formData.append('userId', decoded.userId);
        formData.append('userName', decoded.username);
        formData.append('categorie', categorie);
        formData.append('locatie', locatie);
        formData.append('descriere', descriere);
        formData.append('phone', phone);
        formData.append('pret', pret);
        formData.append('servicii', servicii);
        formData.append('urgent', urgent);
        formData.append('image', selectedImage); // Append the uploaded image file
        
        axios.post('https://node.eurezolv.ro/adaugareAnunt', formData,{
            headers: { 'Content-Type': 'multipart/form-data' ,
                        'Authorization': token  },
        })
            .then((response) => {
                alert(response.data.message);
                navigate('/userprofile');
            })
            .catch((error) => {
                console.error(error);
                alert(error.response.data.message);}
            )
    };

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0];
        setSelectedImage(selectedImage);
      };
    

    return (
        <div className="adaugareAnunt">
            <h2>Adăugare anunț</h2>
            
            
            <form onSubmit={handleFormSubmit}>
                <label htmlFor="numeAnunt">Nume anunț</label>
                <input type="text" id="numeAnunt" value={numeAnunt} onChange={(e) => setNumeAnunt(e.target.value)} />
                
                <label htmlFor="image">Imagine</label>
                <input type="file" id="image" accept="image/*" onChange={handleImageUpload} />


                <label htmlFor="dataExp">Dată expirare</label>
                <input type="date" id="dataExp" value={dataExp} onChange={(e) => setDataExp(e.target.value)} />

                <label htmlFor="categorie">Categorie</label>
                <select id="categorie" value={categorie} onChange={(e) => setCategorie(e.target.value)}>
                {
        categories.map((category, index) => (
          <option
            key={index}
            value={typeof category === 'string' ? category : ''}
            disabled={typeof category !== 'string' && category.disabled}
            style={typeof category !== 'string' ? { fontWeight: 'bold', color: 'black' } : {}}
          >
            {typeof category === 'string' ? category : category.label}
          </option>))}
                </select>

                <label htmlFor="locatie">Locație</label>
                
                <select
                id="locatie"
                value={locatie}
                onChange={(e) =>  setLocatie(e.target.value)}
      >

                {counties.map((county) => (
                <option key={county} value={county}>{county}</option>
                    ))}</select>
                <label htmlFor="descriere">Descriere</label>
                <input type="text" id="descriere" value={descriere} onChange={(e) => setDescriere(e.target.value)} />

                <label htmlFor="pret">Preț</label>
                <input type="text" id="pret" value={pret} onChange={(e) => setPret(e.target.value)} />

                <label htmlFor="phone">Telefon</label>
                <input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                <div>
                <label htmlFor="servicii">Servicii</label>
                <select id="servicii" value={servicii} onChange={(e) => setServicii(e.target.value)}>
                    <option value="1">Serviciu</option>
                    <option value="0">Oferta</option>
                </select>

                <label htmlFor="urgent">Urgent</label>
                <select id="urgent" value={urgent} onChange={(e) => setUrgent(e.target.value)}>
                    <option value="1">Da</option>
                    <option value="0">Nu</option>
                </select>
                </div>

                
                <button className="btn-s" type="submit">Adaugă anunț</button>
            </form> 
        </div>)



}

export default AdaugareAnunt;