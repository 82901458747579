import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import './userpage.css';

function UserPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [userDetails, setUserDetails] = useState(location.state.userDetails);
    const [anunturi, setAnunturi] = useState([]);
    const { userid } = useParams();
    console.log('userid', userid);

    console.log('userDetails', userDetails);
    useEffect(() => {
    axios.get(`https://node.eurezolv.ro/userPageAnunt/${userid}`)
    .then((response) => {
        setAnunturi(response.data);
        console.log('anunturi', anunturi);
    })
    .catch((error) => {

        console.error(error);
    });
    }, []);
    
    return (
        <div style={{width:"100vw"}}>
        <div className="userPage">
            <div className="userPageProfile">
            
                <img src={`https://node.eurezolv.ro/${userDetails?.profile_img}`} alt="imagine profil" className="profile_img"/>
                <div>
                <h4>Username: {userDetails?.username}</h4>  
                <p>{userDetails?.user_description}</p>  
                </div>
            </div>

            <div className="userPageAnunturi">
                <ul className="userPageAnunt">
                    {anunturi.map((selectedItem) => (
                        <li className="upanunt" key={selectedItem.idanunt} onClick={()=>navigate(`/anunt/${selectedItem.idanunt}`, { state: { selectedItem } })}>
                            <img src={`https://node.eurezolv.ro/${selectedItem.image}`} alt="imagine anunt" className="anunt_img"/>
                            <div className="upanunt_details">
                            <h4>{selectedItem.nume_anunt}</h4>
                            <p>{selectedItem.price} RON</p>
                            <p>{new Date(selectedItem.data_add).toLocaleDateString()}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        
        </div>
        </div>
    );




}

export default UserPage;