import React, { useEffect } from "react"; import './signinup.css';
import axios from 'axios';
import { useState } from 'react';
import './SignUp.css';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Recovery from "./Recovery";
import './modal.css';

function SignInUp() { 
    
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cui, setCUI] = useState('');
  const [userType, setUserType] = useState(0);
  const [recoveryQuestion, setRecoveryQuestion] = useState('');
  const [recoveryAnswer, setRecoveryAnswer] = useState('');
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);
  const [same, setSame] = useState('');



  const navigate = useNavigate();
  const regexCUI =/[CFJ]\s?((([1-3]?[0-9])|40)|([51-52]))\/[0-9]{1,9}\/[12][0-9]{3}/;
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      alert('You are already logged in');
      navigate('/');
      
    }
  }, [navigate]);

  const handleFormSubmitIn = (e) => {
    e.preventDefault();

    let formData = new FormData();
    
    formData.append('username', username);
    formData.append('password', password);
;

axios.post('https://node.eurezolv.ro/signin', formData,{
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
        alert(response.data.message);
        localStorage.setItem('token', response.data.token);
        navigate('/');
    })
    .catch((error) => {
        console.error(error);
        alert('Wrong username/password');
    });
;

}

  const handleFormSubmitUp = (e) => {
    e.preventDefault();
    const data = JSON.stringify({
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "username": username,
        "password": password,
        
           });
  
      let formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('username', username);
      formData.append('password', password);
      formData.append('userType', userType);
      formData.append('cui', cui);
      formData.append('recoveryQuestion', recoveryQuestion);
      formData.append('recoveryAnswer', recoveryAnswer);
      
  
      console.log(data)
      // Send the form data to the server
  
    
    axios
      .post('https://node.eurezolv.ro/signup', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        alert(response.data.message);
        // TODO: Handle successful sign-up
        localStorage.setItem('token', response.data.token);
        navigate('/userprofile');
      })
      .catch((error) => {
        console.error(error);
        // TODO: Handle sign-up error
        alert(error.response.data.message);
      });
  }
    const openRecoveryModal = () => {
    setShowRecoveryModal(true);
  };

  const closeRecoveryModal = () => {
    setShowRecoveryModal(false);
  };
  
    useEffect(() => { 
        
        const signUpButton = document.getElementById('signUp'); 
        const signInButton = document.getElementById('signIn'); 
        const container = document.getElementById('container');

    
}, []);

return (
  <div>
    <div className="tot">
        <div  id="container">
            
            <div className="adaugareAnunt">
                <form onSubmit={handleFormSubmitIn}>
                    <h1>Intră în cont</h1>
                    {/* <div className="social-container">
                        <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                        <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                        <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
                    </div> */}
                    <span></span>
                    <input type="text" id="username" value={username} placeholder="Nume Utilizator" onChange={(e) => setUsername(e.target.value)} />
                    <input type="password" id="password" value={password} placeholder="Parola" style={{borderRadius: '5px', border: '2px solid black', boxShadow: '2px 2px 0px 0px #000000'}}onChange={(e) => setPassword(e.target.value)} />
                    <a href="#" onClick={openRecoveryModal}>Ți-ai uitat parola?</a>
                    
                    <button type="submit">Continuă</button>
                </form>
                
            </div>
            <div className="adaugareAnunt">
                <form onSubmit={handleFormSubmitUp}>
                    <h1>Creează-ți cont</h1>
                    {/* <div className="social-container">
                        <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                        <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                        <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
                    </div> */}
                    <select value={userType} onChange={(e) => setUserType(e.target.value)}>
                      <option value="0">Persoana Fizica</option>
                      <option value="1">Persoana Juridica</option>
                    </select>
                    <input id="firstName" type="text" value={firstName} placeholder="Prenume" onChange={(e) => setFirstName(e.target.value)} />
                    <input id="lastName" type="text" value={lastName} placeholder="Nume" onChange={(e) => setLastName(e.target.value)} />
                    <input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)}  placeholder="Nume Utilizator (ex: ionut2023)" />
                    {userType === '1' && (
                    <input
                    id="cui"
                    type="text"
                    value={cui}
                    placeholder="CUI"
                    onChange={(e) => setCUI(e.target.value)}
                /> 
            )}      {cui && ( // Only validate if CUI has a value
            regexCUI.test(cui) ? <span style={{color: 'green'}}>CUI valid</span> : <span style={{color: 'red'}}>CUI invalid</span>
        )}
                    <input id="email" type="email" style={{borderRadius: '5px', border: '2px solid black', boxShadow: '2px 2px 0px 0px #000000'}} value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                    <input id="password" type="password" style={{borderRadius: '5px', border: '2px solid black', boxShadow: '2px 2px 0px 0px #000000'}} value={password} placeholder="Parola" onChange={(e) => {
            setPassword(e.target.value);
            if(e.target.value!==confirmPassword){setSame('Parola nu coincide!')}
            else setSame('')}} />
                    <span>{same}</span>
                    <input id="confirmPassword" style={{borderRadius: '5px', border: '2px solid black', boxShadow: '2px 2px 0px 0px #000000'}} type="password" value={confirmPassword} placeholder="Reintrodu parola" onChange={(e) => {
            setConfirmPassword(e.target.value);
            if(e.target.value!==password){setSame('Parola nu coincide!')}
            else setSame('')}} /> 
                    <input id="recoveryquestion" type="text" value={recoveryQuestion} placeholder="Întrebare pentru parola de recuperare (max. 200 de caractere)" onChange={(e) => setRecoveryQuestion(e.target.value)} />
                    <input id="recoveryanswer" type="text" value={recoveryAnswer} placeholder="Răspuns pentru parola de recuperare (max. 25 caractere)" onChange={(e) => setRecoveryAnswer(e.target.value)} />
                    <Link to="/termeniconditii">Termeni și Condiții</Link>
                    
                    <button style={{marginTop: '10px'}}type="submit">Inregistreaza-te</button>
                </form>
            </div>
            {showRecoveryModal && <Recovery closeModal={closeRecoveryModal} />}
            
    
        </div>
    
        
    </div>
    <Footer/>
    </div>
);
}

export default SignInUp;