import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './userprofile.css';
import AdaugareAnunt from './AdaugareAnunt';
import jwt_decode from 'jwt-decode';
import ListaAnunt from './ListaAnunt';
import Footer from './Footer';



const UserProfile = () => {
  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [password, setPassword] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [userDescription, setUserDescription] = useState('');
  const [imaginica, setImaginica] = useState('');
  const [sterge, setSterge] = useState(false);

  let navigate = useNavigate();

  const token = localStorage.getItem('token');
  const decoded = jwt_decode(token);
  console.log(decoded.userId);
  

  const fetchUserDetails = () => {
    
    axios.post('https://node.eurezolv.ro/userdetails', {userId}, {
      headers: {
        
      }})
      .then((response) => {
        setUserDetails(response.data);
        
        setFirstName(response.data[0].first_name);
        setLastName(response.data[0].last_name);
        setEmail(response.data[0].email);
        setUsername(response.data[0].username);
        setPhoneNumber(response.data[0].phone_number);
        setImaginica(response.data[0].profile_img);
        setUserDescription(response.data[0].user_description);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  

  useEffect(() => {
    

    if (!token) {
      alert('You are not logged in');
      navigate('/signinup');
    } else {
      setUserId(decoded.userId);
      
      
      fetchUserDetails();
      setFirstName(decoded.firstName);
      setLastName(decoded.lastName);
      setEmail(decoded.email);
      setUsername(decoded.username);
      setPhoneNumber(decoded.phoneNumber);
      setProfileImage(decoded.profileImg);
      
      
    
    }
  }, [userId]);

  const handleImageUpload = (e) => {
    const selectedImage = e.target.files[0];
    setProfileImage(selectedImage);
  };

  

  
  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('username', username);
    formData.append('phoneNumber', phoneNumber);
    formData.append('userDescription', userDescription);
    formData.append('profileImage', profileImage);
  
    try {
      const response = await axios.put('https://node.eurezolv.ro/user', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      alert('Information updated successfully!');
    } catch (error) {
      console.error(error);
      alert('An error occurred!');
    }
  };

  const deleteAccount = (userId) => {
    axios.delete(`https://node.eurezolv.ro/userdelete/${userId}`, {
      headers: { Authorization: token }
    })
      .then(() => {
        alert('Contul a fost șters cu succes!');
        navigate('/');
      })
      .catch((error) => {
        console.error(error);
        alert('An error occurred!');
      });
  }

  const confirmDelete = (userId) => {
    const confirmDelete = window.confirm("Ești sigur că dorești ștergerea contului?");
    if (confirmDelete) {
        deleteAccount(userId);
    }
    else {handleSterge()}
  };
  
  const handleSterge = () => {
    sterge? setSterge(false) : setSterge(true);
  }


 

  return (
    <div className="userP1">
    <div className="userProfile">
      {/* <img style={{position:"absolute"}}src={`https://node.eurezolv.ro/${imaginica}`} alt="profile" /> */}
      <div className="numeu">
      <h2>Username: {username}</h2>
      </div>
      <div className="profileU">
        <img src={`https://node.eurezolv.ro/${imaginica}`} alt="profile" />
      <div className="user-delete">
          <h3>Șterge contul:</h3>
          {/* <button onClick={() => confirmDelete(userId)}>Șterge contul</button>
          <h3>Sterge</h3> */}
          {sterge? null : (<button onClick={handleSterge} >Șterge contul</button>)}
          {sterge? ( <div>
            <h3>Ești sigur?</h3>
            <button onClick={() => confirmDelete(userId)}>Da</button>
            <button onClick={handleSterge}>Nu</button>
            
            </div>
            ) : null}

      </div>
      </div>
      
      <div className="profileUdata">
        
      <form onSubmit={handleFormSubmit}>
      <label htmlFor="image">Imagine</label>
                <input type="file" id="image" accept="image/*" onChange={handleImageUpload} />
        <div>
          <label>Prenume:</label>
          <input type="text" value={firstName} placeholder={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div>
          <label>Nume:</label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div className='prof-form'>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className='prof-form'>
          <label>Telefon:</label>
          <input type="tel" value={phoneNumber} placeholder='Introdu-ți numarul de telefon' onChange={(e) => setPhoneNumber(e.target.value)} />
        </div>
        <div>
            <label>Scurtă descriere:</label>
            <input type="text" value={userDescription} onChange={(e) => setUserDescription(e.target.value)} />
         </div>
        
          
        <button type="submit">Salvează modificările</button>
        
      </form>
      
      
      
      
      
      </div>
      
      
      
      <div className="anuntUdata">
      <h3 className="anuntUdata">Anunțurile tale:</h3>
        <ListaAnunt/>
      
      </div>
      
    </div>
    <div className="footer">
          <Footer />
          
    </div>
    </div>
  );
};

export default UserProfile;