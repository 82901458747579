import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import "./anunt.css";
import axios from 'axios';
import { useState } from 'react';
import SearchBar from './SearchBar';
import jwt_decode from 'jwt-decode';
function EditAnunt() {
    
    const location = useLocation();
    const item = location.state.anunt;
    const [userDetails, setUserDetails] = useState({});
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const token = localStorage.getItem('token');
    const decoded = jwt_decode(token);
    const [nume_anunt, setNumeAnunt] = useState(item.nume_anunt);
    const [locationn, setLocationn] = useState(item.location);
    const [description, setDescription] = useState(item.description);
    const [price, setPrice] = useState(item.price);
    const [phone_number, setPhoneNumber] = useState(item.phone_number);
    const [userid, setUserId] = useState(decoded.userId);
    const [itemuserid, setItemUserId] = useState(item.userid);
    const [image, setImage] = useState(item.image);


    const handlePhoneNumberClick = () => {
    setShowPhoneNumber(true);
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(e.target.value);
      console.log(item.id);

      const formData = new FormData();
      formData.append('image', image);
      formData.append('nume_anunt', nume_anunt);
      formData.append('location', locationn);
      formData.append('description', description);
      formData.append('price', price);
      formData.append('phone_number', phone_number);
      formData.append('userid', userid);
      formData.append('itemuserid', itemuserid);
      formData.append('id', item.idanunt);



      axios.put(`https://node.eurezolv.ro/editanunt/${item.idanunt }  `, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',}
      })
      .then((response) => {
        console.log(response);
        alert("Anuntul a fost editat cu succes")
      })
      .catch((error) => {
        console.error(error);
      });

    }

    useEffect(() => {
      
    }, []);

    const handleImageUpload = (e) => {
     const file = e.target.files[0];
     setImage(file);
    }
  

    return (
      <div className='anunt-container'>
        <div className="searchaa">
          <SearchBar/>
        </div>
        <div className=".">
          </div>

        <div className="anuntDet">

        

        <div className="anunt-details" style={{justifyItems:"center"}}>
        <img style={{width:"100%"}}src={`https://node.eurezolv.ro/${item.image}`} alt="imagine anunt" />
        
        

        <div className="anunt-edit">
        <h2>Editeaza anuntul</h2>
        <form style={{backgroundColor:"transparent", color:"black"}}onSubmit={handleSubmit}>

        <label htmlFor="image">Imagine</label>
                <input type="file" id="image" accept="image/*" onChange={handleImageUpload} />
          <label>Nume anunt:</label>
          <input type="text" name="nume_anunt" value={nume_anunt} onChange={(e)=>setNumeAnunt(e.target.value)    }/>
          <label>Locație:</label>
          <input type="text" name="location" value={locationn} onChange={(e)=>setLocationn(e.target.value)} />
          <label>Categorie:</label>
          <p>Categoria: {item.category}</p>
          <input type="text" name="description" value={description}   onChange={(e)=>setDescription(e.target.value)}   />
          <label>Preț:</label>
          <input type="text" name="price" value={price} onChange={(e)=>setPrice(e.target.value)}/>
          
          <label>Număr telefon:</label>
          <input type="text" name="phone_number" value={phone_number} onChange={(e)=>setPhoneNumber(e.target.value)} />
          
          <button type="submit">Editeaza</button>
        </form>
        </div>
        
        
        </div>
        </div>

        





      </div>
    );
  }

  export default EditAnunt;