import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import ListaAnunt from "./ListaAnunt";
import './admindash.css';

function AdminDash() {

    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [sterge, setSterge] = useState(false);
    
    const handleUserClick = (userId) => {
        setSelectedUserId(userId === selectedUserId ? null : userId);
    };

    const token = localStorage.getItem('token');

    useEffect(() => {
        axios.get('https://node.eurezolv.ro/adminusers', {headers: { Authorization: token}})
            .then((response) => {
                setUsers(response.data);
                console.log(response.data)
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);

    const deleteUser = (userId) => {
        axios.delete(`https://node.eurezolv.ro/userdelete/${userId}`, {headers: { Authorization: token}})
            .then(() => {
                setUsers(users.filter((user) => user.id !== userId));
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const confirmDelete = (userId) => {
        const confirmDelete = window.confirm("Ești sigur că dorești ștergerea contului?");
        if (confirmDelete) {
            deleteUser(userId);
            handleSterge();
        }
        else {handleSterge()}
      };
      
      const handleSterge = () => {
        sterge? setSterge(false) : setSterge(true);
      }

    return (
        <div className="admin">
            <h1>Admin Dashboard</h1>
            <div className="users">
                {users.map((user) => (
                    <div>
                    <div className="user" key={user.iduser} onClick={() => handleUserClick(user.id)}>
                        <img src={`https://node.eurezolv.ro/${user.profile_img}`} alt="profile_picture" width="100" height="100" />
                        <div className="user-info">
                            <h4>{user.username}</h4>
                            <p>{user.first_name} {user.last_name}</p>
                            <p>{user.phone_number}</p>
                            <p>{user.email}</p>
                        </div>
                        <p className="pdescription">{user.user_description}</p>
                        
                        {/* <button onClick={() => deleteUser(user.id)}>Delete</button> */}
                        {sterge? null : (<button onClick={handleSterge} >Șterge contul</button>)}
                    {sterge? ( <div style={{alignSelf:"center"}}>
            
                    <button onClick={() => confirmDelete(user.id)}>Da</button>
                    <button onClick={handleSterge}>Nu</button>
            
                    </div>
                    ) : null}
                    </div>
                    
                    <div className="listaadmin">
                    {selectedUserId === user.id && <ListaAnunt propUserid={user.id} />}
                    </div>
                    </div>
                ))}
            </div>
        </div>
    );


}

export default AdminDash;