import React from "react";
import axios from 'axios';
import { useState } from 'react';
import './SignUp.css';

function SignUp () {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [same, setSame] = useState('');



  const handleFormSubmit = (e) => {
    e.preventDefault();

    //TODO: Implement data validation and handle errors
    // const data = JSON.stringify({
    //   firstName,
    //   lastName,
    //   email,
    //   username,
    //   password,
    //   phoneNumber,
    // });
    
    const data = JSON.stringify({
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "username": username,
      "password": password,
      "phoneNumber": phoneNumber    });

    let formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('username', username);
    formData.append('password', password);
    formData.append('phoneNumber', phoneNumber);

    console.log(data)
    // Send the form data to the server

  
  axios
    .post('https://node.eurezolv.ro/signup', formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      alert(response.data.message);
      // TODO: Handle successful sign-up
    })
    .catch((error) => {
      console.error(error);
      // TODO: Handle sign-up error
    });
  };
  

  return (
    <div className="App">
      <h1>Sign Up</h1>
    
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="firstName">
          First Name:
          <input id="firstName" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </label>
        <br />
        <label htmlFor="lastName">
          Last Name:
          <input id="lastName" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </label>
        <br />
        <label htmlFor="email">
          Email:
          <input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <br />
        <label htmlFor="username">
          Username:
          <input id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <br />
        <label htmlFor="password">
          Password:
          <input id="password" type="password" value={password} onChange={(e) => {
            setPassword(e.target.value);
            if(e.target.value!==confirmPassword){setSame('Not the same')}
            else setSame('')}
          
          } />
        </label>
        <br/>{same}
        <label htmlFor="confirmPassword">
          Confirm Password: 
          <input id="confirmPassword" type="password" value={confirmPassword} onChange={(e) => {
            setConfirmPassword(e.target.value);
            if(e.target.value!==password){setSame('Not the same')}
            else setSame('')}} />
          
        </label>
        <br />
        <label htmlFor="phoneNumber">
          Phone Number:
          <input id="phoneNumber" type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
        </label>
        <br />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );

}

export default SignUp;
