import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './search.css';
import AnuntD from './AnuntD';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { sresults } from './Home';

function SearchU(props) {
  let [searchTerm, setSearchTerm] = useState(props.searchTerm);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState([]);

  const searchFunction = () => {
    const formData = new FormData();

    formData.append('searchTerm', searchTerm);

    axios
      .post('https://node.eurezolv.ro/search', formData, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((res) => {
        setSearchResults(res.data);
      })
      .catch((err) => {
        console.log(err);
        alert('Eroare la cautare');
      });
  };

  useEffect(() => {
    searchFunction();
  }, [searchTerm]);

  const nume = props.nume;

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleGoBack = () => {
    setSelectedItem(null);
  };

  console.log("searchU",sresults.value);

  const settings = {
    
    dots: false,
    infinite: true  ,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    useTransform: true,
    adaptiveHeight: false,
    centerPadding: "30px",
    
    
    
    
    
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className='cont'>
      <h4 style={{margin:"1px", color:"black"}}>Anunturi urgente</h4>
      <div className='urgent' style={{width:'100%'}}>
        <Slider {...settings}>
          {searchResults.map((result) => (
            <div className='anunt-pr' style={{margin: "5px 10px"}} key={result.id} onClick={() => handleItemClick(result)}>
              <img src={`https://node.eurezolv.ro/${result.image}`} alt='imagine anunt' id='img-anunt' />
              <li style={{height:"100px"}}>
                <h3>{result.nume_anunt}</h3>
                {/* <p>{result.description}</p> */}
                <p>{result.price} RON</p>
                <p>{new Date(result.data_add).toLocaleDateString()}</p>
              </li>
            </div>
          ))}
        </Slider>
      </div>
      {selectedItem ? navigate(`/anunt/${selectedItem.idanunt}`, { state: { selectedItem } }) : null}
    </div>
  );
}

export default SearchU;
