import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './search.css';
import AnuntD from './AnuntD';
import { useLocation, useNavigate } from "react-router-dom";
import {sresults} from "./Home";

function Search(props) {
  let [searchTerm, setSearchTerm] = useState('all');
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    if(props.searchTerm!==''){setSearchTerm(props.searchTerm)};
  }, [props.searchTerm]);

  const [searchResults, setSearchResults] = useState([]);

  sresults.value=searchResults;
  console.log("search",sresults.value);
  const searchFunction = () => {
    //new form data
    const formData = new FormData();
    formData.append('searchTerm', searchTerm);
    
    axios
      .post('https://node.eurezolv.ro/search', formData, {
        headers: { 'Content-Type': 'application/json' }})
        .then((res) => {
            setSearchResults(res.data);
            }
        )
        .catch((err) => {
            console.log(err);
            alert("Eroare la cautare");
        }
        );
  }

  useEffect(() => {
    searchFunction();
    
  }, [searchTerm]);

  const nume = props.nume;

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleGoBack = () => {
    setSelectedItem(null);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
  
    // Ensure two digits for day and month
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
  
    return `${formattedDay}/${formattedMonth}/${year}`;
  }



  return (
    <div className='cont'>
    <div className="search-results">
      {searchResults.length > 0 ? (
        <ul>
          {sresults.value
            .sort((a, b) => new Date(b.data_add) - new Date(a.data_add)) // Sort in descending order
            .slice(0, props.elemente)
            .map((result) => (
              <div className='anunt-pr'  key={result.id} onClick={() => handleItemClick(result)}>
                <img src={`https://node.eurezolv.ro/${result.image}`} alt="imagine anunt" id="img-anunt" />
                <li>
                  <h3>{result.nume_anunt}</h3>
                  <p>{result.price} RON</p>
                  <p>{formatDate(result.data_add)}</p>
                </li>
              </div>
            ))}
        </ul>
      ) : (
        <p>Nu a fost găsit niciun rezultat.</p>
      )}
      {selectedItem ? navigate(`/anunt/${selectedItem.idanunt}`, { state: { selectedItem } }) : null}
    </div>
  </div>
);
}

export default Search;