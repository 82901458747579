import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import "./anunt.css";
import axios from 'axios';
import { useState } from 'react';
import SearchBar from './SearchBar';
import Contact from './Contact';
import Footer from './Footer';
import SearchU from './SearchU';
import SearchAse from './SearchAse';
import { sresults } from './Home';
import {useMemo} from 'react';
import { useNavigate } from 'react-router-dom';

function Anunt() {
    
    const location = useLocation();
    const [item,setItem] = useState(location.state.selectedItem);
    const [userDetails, setUserDetails] = useState({});
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const navigate = useNavigate();
    

    //const [results, setResults] = useState(location.state.searchResults);
    const handlePhoneNumberClick = () => {
    setShowPhoneNumber(true);
    };

    const changeAnunt = (item) => {
      setItem(item);
    }


    
    console.log("Anunt", sresults.value);
    useEffect(() => {
      // axios.post('https://node.eurezolv.ro/userdetails', { userId: item.userid }, {
      //   headers: {
          
      //   }
      // })
      // .then((response) => {
      //   setUserDetails(response.data);
        
      // })
      // .catch((error) => {
      //   console.error(error);
      // });

      axios.get(`https://node.eurezolv.ro/userdetailsanunt/${item.userid}`)
      .then((response) => {
        setUserDetails(response.data[0]);
        
      })
      .catch((error) => {
        console.error(error);
      });
      


    }, [item]);
  

    return (
      <div className='anunt-container'>
        <div className="searchaa">
          <SearchBar/>
        </div>
        <div className=".">
          </div>

        <div className="anuntDet">

        
        
        <div className="anunt-details">
        <div className="anunt-text">

        <h3 style={{ textAlign: "center", marginBottom:"0px"}}>{item.nume_anunt}</h3>
        <div className="anunt-titlu-text" style={{display:"flex", justifyContent:"space-between"}}>
        <h5>Data adaugarii: {new Date(item.data_add).toLocaleDateString()}</h5>

        <h5>{item.location}</h5>

        

        </div>
        <img src={`https://node.eurezolv.ro/${item.image}`} alt="imagine anunt" />
        
        
        
        
        
        <p>Categoria: {item.category}</p>
        
        
        <p>Descriere: {item.description}</p>
        <p>Prețul: {item.price} RON</p>
        <div className="telefon" onClick={handlePhoneNumberClick}>
            Telefon: {showPhoneNumber ? item.phone_number : "Apasa pentru a vedea numarul de telefon"}
        </div>
        
        </div>
        </div>
        </div>

        <div className="user-details" onClick={()=>navigate(`/user/${item.userid}`, {state: {userDetails}})}>
        {/* <h3 style={{ textAlign: "center"}}>Datele utilizatorului</h3> */}
        <img src={`https://node.eurezolv.ro/${userDetails?.profile_img}`} alt="imagine profil" className="profile_img"/>
        <h4>Username: {userDetails?.username}</h4>
        <br>
        </br>
        <p>{userDetails?.user_description}</p>
        

        </div>

        <div className="asemanatoare">
        <h3 style={{ textAlign: "center"}}>Anunturi asemanatoare</h3>
        
        <SearchAse changeAnunt={changeAnunt}/>
        
        
        </div>



        <div className="footer">
          <Footer />
    
        </div>


        
      </div>
      
      
    );
  }

  export default Anunt;