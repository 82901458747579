import React, { useState, useEffect } from "react";
import axios from "axios";


const Recovery = ({ closeModal }) => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [responseMessage, setResponseMessage] = useState('');
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [recoveryQuestion, setRecoveryQuestion] = useState('');
    const [responsePassword, setResponsePassword] = useState('');

    
     // Function to handle closing modal when clicking outside
     const handleClickOutside = (event) => {
      if (event.target.classList.contains("modal")) {
          closeModal();
      }
  };

  // Attach event listener when component mounts
  useEffect(() => {
      document.addEventListener("click", handleClickOutside);
      return () => {
          document.removeEventListener("click", handleClickOutside);
      };
  }, []); // Empty dependency array ensures the effect runs only once
    
    
    
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put('https://node.eurezolv.ro/recovery', { email, username });
            setResponseMessage(response.data.message);
            if (response.status === 200) {
                setVerificationSuccess(true);
                setRecoveryQuestion(response.data.recoveryQuestion); // Set recovery question in state
            }
        } catch (error) {
            console.error(error);
            setResponseMessage('Error occurred while retrieving recovery question');
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put('https://node.eurezolv.ro/recoverypassword', { email, username, recovery_answer: password });
            setResponseMessage(response.data.message);
            if (response.data.password) {
                setResponsePassword(response.data.password);
            }
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Error occurred while processing request.');
        }
    };

    return (
        <div className="modal">
            <div className="modal-content adaugareAnunt">
              
                <span className="close" onClick={closeModal}>&times;</span>
                <h2>Recover Password</h2>
                <form onSubmit={handleSubmit}>
                {verificationSuccess===false && (<div>
                    <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    
                    
                    <button type="submit">Verifică</button></div>)}
                    
                </form>
                {verificationSuccess && (
                        <div>
                            
                            <form onSubmit={handlePasswordSubmit}>
                            {!responsePassword && (
                            <div>
                            <p>{recoveryQuestion}</p>
                                
                                <input type="text" placeholder="Introdu raspunsul pentru recuperare" value={password} onChange={(e) => setPassword(e.target.value)} />
                                
                                <button type="submit">Recuperează</button></div>)}
                            </form>
                        </div>
                    )}
                    {responsePassword && <p>Parola ta este: {responsePassword}</p>}
            </div>
        </div>
    );
};

export default Recovery;

//ionelut@ionic.ro ionicut