import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './search.css';
import AnuntD from './AnuntD';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { sresults } from './Home';
import {useMemo} from 'react';

function SearchAse(changeAnunt) {
  //let [searchTerm, setSearchTerm] = useState(props.searchTerm);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const searchResults = useMemo(() => sresults.value, [sresults.value]);

  // const searchFunction = () => {
  //   setSearchResults(sresults.v);
  // };



  console.log("SearchAse",sresults);
  

 


  

  const handleItemClick = (item) => {
    changeAnunt.changeAnunt(item);
    const targetElement = document.querySelector('.anunt-details');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleGoBack = () => {
    setSelectedItem(null);
  };

  

  const settings = {
    dots: false,
    infinite: true  ,
    
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: false,
    speed: 300,
    autoplaySpeed: 3000,

    
    centerMode: true,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    useTransform: false,
    adaptiveHeight: false,
    
    
    
    
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className='cont'>
      <div className='urgent' style={{width:'100%'}}>
        <Slider style={{height:"300", zIndex:"1"}} {...settings}>
          {searchResults.map((result) => (
            <div className='anunt-pr slide-margin' style={{margin: "5px 10px"}} key={result.id} onClick={() => handleItemClick(result)}>
              <img src={`https://node.eurezolv.ro/${result.image}`} alt='imagine anunt' id='img-anunt' />
              <li style={{height:"100px"}}>
                <h3>{result.nume_anunt}</h3>
                {/* <p>{result.description}</p> */}
                <p>{result.price} RON</p>
                <p>{new Date(result.data_add).toLocaleDateString()}</p>
              </li>
            </div>
          ))}
        </Slider>
      </div>
      
    </div>
  );
}

export default SearchAse;
